(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

/**
 * Created by prasong.k on 19/9/2559.
 */
var env = JSON.parse(atob(window.env));

function isInteger(x) {
    return typeof x === "number" && isFinite(x) && Math.floor(x) === x;
}
function isFloat(x) {
    return !!(x % 1);
}

//vue http
window.vue_http_options = {
    before: function before(request) {
        setTimeout(function () {
            document.getElementById('loading').style.display = 'block';
        }, 1000);
    }
};
window.ckeditor_options = {
    extraPlugins: 'justify',
    filebrowserUploadUrl: '/api/ck_editor/upload'
};
var $loading = $('#loading');
window.$loading_hide = function () {
    setTimeout(function () {
        $loading.hide();
    }, 1000);
};
window.$form_init = function () {
    setTimeout(function () {
        //not include select2,datatable:show_entries
        $('select:not(.select2, [name*="_length"])').material_select();
        $(".tooltipped").tooltip();
    }, 500);
};

$(document).ready(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('body').data('csrf-token'),
            'X-XSRF-TOKEN': $('body').data('csrf-token')
        }
    });

    $(document).ajaxStart(function () {
        $loading.show();
    });
    $(document).ajaxStop(function () {
        $loading.hide();
    });
});

// require('./rollbar')(env);

window.tableToExcel = function () {
    var uri = 'data:application/vnd.ms-excel;base64,',
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><?xml version="1.0" encoding="UTF-8" standalone="yes"?><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64 = function base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
    },
        format = function format(s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
            return c[p];
        });
    };
    return function (table, name, filename) {
        if (!table.nodeType) table = document.getElementById(table);
        var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };

        document.getElementById("tmpJSExport").href = uri + base64(format(template, ctx));
        document.getElementById("tmpJSExport").download = filename;
        document.getElementById("tmpJSExport").click();
    };
}();

},{}]},{},[1]);
